.slide-horizontal-enter {
    z-index: 12;
    position: absolute;
    transform: translateX(calc((-1) * (38.2vw / 2 + 61.8vw * 0.382 - 15px - 19vw))) scaleX(.5);
    transition: transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 100ms;
    -webkit-transform: translateX(calc((-1) * (38.2vw / 2 + 61.8vw * 0.382 - 15px - 19vw))) scaleX(.5);
    -webkit-transition: transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 100ms;
}
.slide-horizontal-enter.slide-horizontal-enter-active {
    z-index: 12;
    transform: scaleX(1);
    transition: transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    -webkit-transform: scaleX(1);
    -webkit-transition: transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.slide-horizontal-exit {
    position: absolute;
    z-index: 1;
}

.slide-horizontal-exit.slide-horizontal-exit-active {
    z-index: 1;
}

.slide-vertical-enter {
    z-index: 12;
    position: absolute;
    transform: scaleY(0);
    -webkit-transform: scaleY(0);
}
.slide-vertical-enter.slide-vertical-enter-active {
    z-index: 12;
    transform: scaleY(1) ;
    transform-origin: top left;
    transition: transform 500ms cubic-bezier(0.4, 0, 0.2, 1) 100ms !important;
    -webkit-transform: scaleY(1) ;
    -webkit-transform-origin: top left;
    -webkit-transition: transform 500ms cubic-bezier(0.4, 0, 0.2, 1) 100ms !important;
}
.slide-vertical-exit {
    position: absolute;
    z-index: 1;
    transform: scaleY(0) ;
    transform-origin: top left;
    transition: transform 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
    -webkit-transform: scaleY(0) ;
    -webkit-transform-origin: top left;
    -webkit-transition: transform 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
}
.slide-vertical-exit.slide-horizontal-exit-active {
    z-index: 1;
}